<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-21 16:15:25
 * @LastEditTime: 2022-05-18 14:59:52
 * @Description: 列表查询组件
-->
<template>
  <div class="ykc-search-box">
    <div class="ykc-search" :class="{ 'open-state': openState }">
      <ykc-form :model="ruleForm" ref="searchForm" labelPosition="right" :style="formStyle">
        <div class="ykc-search-item" ref="itemWrap">
          <ykc-form-item
            :prop="item.key"
            v-for="(item, index) in data"
            :key="index"
            :style="{ width: `${100 / dataSum}%` }">
            <template slot="label">
              <span :style="{ width: setWidth(item, index) }">{{ `${item.label}：` }}</span>
            </template>
            <component
              v-if="!item.isDouble"
              v-bind="item"
              :is="item.comName"
              :dropDefault="dropDefault"
              v-model="ruleForm[item.key]"
              @change="handleChange($event, item)"></component>
            <div v-else class="flex doubleInput">
              <component
                v-bind="item"
                :is="item.comName"
                :dropDefault="dropDefault"
                v-model="ruleForm[item.key1]"
                @change="handleChange($event, item)">
                <template slot="append" v-if="item.appendText && item.appendText !== ''">
                  {{ item.appendText }}
                </template>
              </component>
              <span>~</span>
              <component
                v-bind="item"
                :is="item.comName"
                :dropDefault="dropDefault"
                v-model="ruleForm[item.key2]"
                @change="handleChange($event, item)">
                <template slot="append" v-if="item.appendText && item.appendText !== ''">
                  {{ item.appendText }}
                </template>
              </component>
            </div>
          </ykc-form-item>
        </div>
      </ykc-form>
      <div class="seach-button">
        <ykc-button @click="searchBtn">{{ confirmBtnLabel }}</ykc-button>
        <ykc-button type="plain" @click="cleanBtn" v-if="showclearBtnLabel">
          {{ clearBtnLabel }}
        </ykc-button>
      </div>
      <div class="openAndclose" v-if="data.length > column">
        <ykc-button type="text" @click="openState = !openState">
          {{ openState ? '收起' : '展开' }}
          <ykc-icon class="ykc-icon-unfold" :class="openState && 'rotate'"></ykc-icon>
        </ykc-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';
  import YkcInput from '@/components/ykc-ui/input';
  import YkcDropdown from '@/components/ykc-ui/dropdown';
  import YkcDatePicker from '@/components/ykc-ui/date-picker';
  import YkcCascader from '@/components/ykc-ui/cascader';

  export default {
    name: 'YkcSearch',
    props: {
      // 查询数据源:
      /**
       * [
        { comName: 'YkcInput', key: 'test1', label: '测试测试', placeholder: '请输入测试01' },
        { comName: 'YkcDatePicker', key: 'date', label: '结算日期' },
        { comName: 'YkcDropdown', key: 'dropdown', label: '下拉框', placeholder: '请选择', data: [{ id: '1', name: '选项1' }]},
        { comName: 'YkcInput', key: 'test3', label: '测试测试', placeholder: '请输入测试01' },
        { comName: 'YkcInput', key: 'test4', label: '测试', placeholder: '请输入测试01' },
      ]
      */
      data: {
        type: Array,
        default: () => [],
      },
      searchForm: {
        type: Object,
        default: () => ({}),
      },
      // 清空按钮标签
      clearBtnLabel: {
        type: String,
        default: '清空',
      },
      // 显示清空按钮标签
      showclearBtnLabel: {
        type: Boolean,
        default: true,
      },
      // 确认按钮标签
      confirmBtnLabel: {
        type: String,
        default: '确认',
      },
    },
    mixins: [windowResizeMixin],
    components: {
      YkcInput,
      YkcDropdown,
      YkcDatePicker,
      YkcCascader,
    },
    data() {
      return {
        ruleForm: {},
        // 默认一行4列
        column: 4,
        openState: false,
        // 单个字符width
        fontWidth: 12,
        itemWrapHeight: 44,
        maxLabale: [],
        dropDefault: '',
        isClean: false,
      };
    },
    watch: {
      searchForm: {
        // 是否深度监听
        deep: true,
        // 首次绑定是否执行handle
        immediate: true,
        handler(form) {
          this.ruleForm = form;
        },
      },
    },
    created() {
      // this.getMaxLabel();
    },
    mounted() {
      addResizeListener(this.$refs.itemWrap, this.getWrapHeight);
      this.$on('hook:beforeDestroy', () => {
        removeResizeListener(this.$refs.itemWrap, this.getWrapHeight);
      });
    },
    computed: {
      /**
       * 根据数据设置 ykc-form 样式
       */
      formStyle() {
        const base = this.column > 3 ? 25 : 33.3;
        const formStyle =
          this.dataSum < this.column
            ? { width: `${this.dataSum * base}%` }
            : { flex: 1, height: this.openState ? `${this.itemWrapHeight}px` : '44px' };
        return formStyle;
      },
      /**
       * 根据数据设置每项的width
       */
      dataSum() {
        const len = this.data.length;
        return len < this.column ? len : this.column;
      },
    },
    methods: {
      /** 根据元素计算高度 */
      getWrapHeight() {
        this.itemWrapHeight = this.$refs.itemWrap.offsetHeight;
      },
      /**
       * 根据 resize变化设置样式
       */
      calculateSize(isExpanded) {
        this.column = isExpanded ? 4 : 3;
        this.getMaxLabel();
      },
      /**
       * 获取最长的label
       */
      getMaxLabel() {
        this.maxLabale = [];
        new Array(this.column).fill(1).forEach((k, index) => {
          // 获取每列label的length
          const arr = this.data
            .filter((item, idx) => idx % this.column === index)
            .map(item => item.label.length);
          // 使用max获取最长的label存放到指定数组
          this.maxLabale.push(Math.max(...arr));
        });
        // console.log(this.maxLabale);
      },
      /** *
       * 设置每列label的width
       */
      setWidth(item, index) {
        let w = '';
        this.maxLabale.forEach((k, idx) => {
          // 设置每列label的width
          if (index % this.column === idx) {
            // 字数 * 12 + 12（冒号）
            w = `${k * this.fontWidth + this.fontWidth}px`;
          }
        });
        return w;
      },
      /**
       * 查询筛选按钮
       */
      searchBtn() {
        this.dropDefault = '';
        // this.openState = false;
        this.$emit('click', this.ruleForm);
      },
      /**
       * 清空查询框
       */
      cleanBtn() {
        Object.entries(this.ruleForm).forEach(([k, v]) => {
          if (Array.isArray(v)) {
            this.ruleForm[k] = [];
            this.dropDefault = 'daterange';
          } else {
            this.ruleForm[k] = '';
          }
        });
        // this.$refs.searchForm.resetFields();
        this.$emit('clean', this.ruleForm);
      },
      /**
       * 选择框选择操作
       */
      handleChange(v, obj) {
        if (obj.comName === 'YkcDatePicker' || obj.comName === 'YkcDropdown') {
          this.dropDefault = '';
          // eslint-disable-next-line no-unused-expressions
          obj.change && obj.change(v, obj);
        }
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
