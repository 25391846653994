<!--
 * @Author: Daokai Huo
 * @Date: 2021-09-24 11:29:20
 * @LastEditTime: 2021-11-14 11:22:39
 * @Description: button组件
-->
<template>
  <el-button
    ref="btn"
    class="ykc-button"
    :type="type"
    :icon="btnIcon"
    :loading="loading"
    :disabled="disabled"
    :style="{ padding: padding }"
    :class="[clickStatus ? 'not-allowed' : '', btnIcon && 'icon-btn']"
    @click="handleClick">
    <slot>按钮</slot>
  </el-button>
</template>
<script>
  /**
   * 防抖函数
   */
  function debounce(func, wait = 1000, immediate = true) {
    let timeout = null;
    return function fn() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      if (timeout) {
        clearTimeout(timeout);
      }
      // 首次立即执行
      if (immediate) {
        const nowExe = !timeout;
        timeout = setTimeout(() => {
          timeout = null;
        }, wait);
        if (nowExe) {
          func.apply(this, args);
        }
      } else {
        // 首次延迟执行
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      }
    };
  }
  export default {
    name: 'YkcButton',
    inheritAttrs: false,
    props: {
      // 类型 primary / primary-plain / plain / text
      type: {
        type: String,
        default: 'primary',
        validator(val) {
          const typeArr = ['primary', 'primary-plain', 'plain', 'text'];
          return typeArr.includes(val);
        },
      },
      // icon按钮（自定icon类名, 如：icon-enable）
      btnIcon: String,
      // btn禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      //
      padding: {
        type: String,
        default: '',
      },
      // 防抖延迟时间
      delayTime: {
        type: Number,
        default: 500,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFocus: false,
        clickStatus: false,
      };
    },
    created() {
      // 按钮创建单独实例
      this.handleClick = debounce(this.handleClick, this.delayTime);
    },
    methods: {
      /**
       * 按钮点击事件
       */
      handleClick(event) {
        if (this.disabled) {
          return;
        }
        this.$emit('click', event);
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
