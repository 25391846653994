<!--
 * @Descripttion: tree
 * @Author: 汪佳彪
 * @date: 2021-10-28 13:26
-->
<template>
  <div
    class="ykc-tree"
    :style="{ width: format(width), paddingTop: !searchable && '0' }"
    v-loading="loading"
    :tabindex="-1"
    @blur="handleBlur">
    <div class="header" v-if="selectable || searchable">
      <el-checkbox
        class="select"
        v-if="
          selectType === 'checkbox' && data.length > 0 // 没有数据时不展示复选框
        "
        :value="isAllChecked()"
        :indeterminate="isAllIndeterminate()"
        @click.native.prevent="handleSelectAll">
        {{ selectAllText }}
      </el-checkbox>
      <div class="search" v-if="searchable">
        <ykc-input
          class="ykc-tree-search-input"
          v-model="searchValue"
          :clearable="true"
          :placeholder="placeholder"
          :validate-event="false"
          @clear="handleSearch"
          @keyup.native="handleKeyup"></ykc-input>
        <ykc-button style="margin-left: -1px" @click="handleSearch">{{ searchText }}</ykc-button>
      </div>
    </div>
    <ykc-tree-list ref="YkcTreeList" :height="treeHeight" :width="width" :data="listData">
      <template v-slot="{ items }">
        <!-- <transition-group name="el-zoom-in-center"> -->
        <template v-if="items.length > 0">
          <div
            v-for="item in items"
            :key="item[nodeKey]"
            @click="handleNodeClick(item, item[nodeKey])"
            @contextmenu="handleNodeContextmenu(item, item[nodeKey], $event)"
            :class="[
              'ykc-tree-node',
              {
                'is-root': isRootNode(item),
              },
            ]"
            :style="{
              paddingLeft: `${selectable ? 12 : 32}px`,
            }">
            <div v-if="selectable" class="select">
              <el-checkbox
                v-if="selectType === 'checkbox'"
                :value="isNodeSelected(item)"
                :indeterminate="isNodeIndeterminate(item)"
                :disabled="isNodeDisabled(item)"
                @click.native.prevent="
                  handleCheckboxClick(item, item[nodeKey], $event, true)
                "></el-checkbox>
              <el-radio
                v-else-if="selectType === 'radio'"
                :value="isNodeSelected(item)"
                :label="true"
                :disabled="isNodeDisabled(item)"
                @click.native.prevent="handleRadioClick(item, item[nodeKey], $event)"></el-radio>
            </div>
            <div
              class="content"
              :style="{
                paddingLeft: calculatePaddingLeft(item),
              }">
              <i
                v-if="isNodeHasChildren(item)"
                :class="['icon', `el-icon-caret-${isNodeExpanded(item) ? 'bottom' : 'right'}`]"
                @click="handleExpandClick(item, item[Expanded], item[nodeKey], $event)"></i>
              <slot name="node" :node="item">
                <div :class="['label', { 'is-leaf': !isNodeHasChildren(item) }]">
                  {{ renderLabel(item) }}
                </div>
              </slot>
            </div>
          </div>
        </template>
        <!-- </transition-group> -->
        <template v-else>
          <slot name="empty">
            <!-- <div class="empty">暂无数据</div> -->
            <div class="ykc-tree-empty">
              <img :src="emptyImageURL" alt="tree-empty" />
              <span>{{ emptyText }}</span>
            </div>
          </slot>
        </template>
      </template>
    </ykc-tree-list>
  </div>
</template>

<script>
  import YkcButton from '@/components/ykc-ui/button';
  import YkcInput from '@/components/ykc-ui/input';
  import YkcTreeList from './list.vue';
  import noDataImg from '@/components/ykc-ui/assets/no-data.png';

  const SymbolMap = {
    Expanded: Symbol('Expanded'),
    Depth: Symbol('Depth'),
    Uid: Symbol('Uid'),
    Parent: Symbol('Parent'),
    HasChildren: Symbol('HasChildren'),
    DescendentsCheckedCount: Symbol('DescendentsCheckedCount'),
    DescendentList: Symbol('DescendentList'),
  };

  export default {
    name: 'YkcTree',
    inheritAttrs: false,
    components: {
      YkcTreeList,
      YkcButton,
      YkcInput,
    },
    inject: {
      elFormItem: {
        default: null,
      },
    },
    props: {
      /** 空数据时展示的图片地址 */
      emptyImageURL: {
        type: String,
        default: noDataImg,
      },
      /** 空数据时显示的文本内容，也可以通过 `slot="empty"` 设置 */
      emptyText: {
        type: String,
        default: '暂无数据',
      },
      height: {
        type: [Number, String],
        default: 290,
      },
      width: {
        type: [Number, String],
        default: '480',
      },
      selectType: {
        type: String,
        default: 'checkbox',
        validator(val) {
          return ['none', 'radio', 'checkbox'].includes(val);
        },
      },
      selectAllText: {
        type: String,
        default: '全选',
      },
      searchable: {
        type: Boolean,
        default: true,
      },
      searchText: {
        type: String,
        default: '搜索',
      },
      /** 搜索时，是否展示节点的子元素 */
      childrenVisibleWhenSearch: {
        type: Boolean,
        default: true,
      },
      placeholder: {
        type: String,
        default: '请输入关键字进行过滤',
      },
      data: {
        type: Array,
        default: () => [],
      },
      /** 菜单项的id属性 */
      nodeKey: {
        type: String,
        default: 'id',
      },
      /** 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。 */
      expandOnClickNode: {
        type: Boolean,
        default: true,
      },
      /** 是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。 */
      checkOnClickNode: Boolean,
      /** 缩进 */
      indent: {
        type: Number,
        default: 16,
      },
      /** 当仅有部分行被选中时，点击头部的多选框时的行为。
       * 若为 true，则选中所有行；若为 false，则取消选择所有行
       */
      selectOnIndeterminate: {
        type: Boolean,
        default: true,
      },
      /** 是否默认展开所有节点 */
      defaultExpandAll: Boolean,
      /** 配置选项 */
      props: {
        type: Object,
        default: () => ({
          label: 'label',
          children: 'children',
          disabled: 'disabled',
        }),
        validator(val) {
          const keys = Object.keys(val);
          return (
            keys.every(key => ['label', 'children', 'disabled'].includes(key)) &&
            ['string', 'function'].includes(typeof val.label) &&
            ['string'].includes(typeof val.children) &&
            ['string', 'function'].includes(typeof val.disabled)
          );
        },
      },
      /** 是否每次只打开一个同级树节点展开 */
      accordion: Boolean,
      /** 单选时是否可以取消选中 */
      radioToogleable: {
        type: Boolean,
        default: true,
      },
      /** 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false */
      checkStrictly: Boolean,
      /** 精确搜索 */
      exactSearch: Boolean,
      /** 是否进行表单验证 */
      validateEvent: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: true,
        DFSListData: [],
        listData: [],
        expandedNodes: new Set(),
        searchValue: '',
        searchData: [],
        selectedNodes: new Set(),
      };
    },
    computed: {
      selectable() {
        return this.selectType !== 'none';
      },
      DescendentsCheckedCount() {
        return SymbolMap.DescendentsCheckedCount;
      },
      DescendentList() {
        return SymbolMap.DescendentList;
      },
      Expanded() {
        return SymbolMap.Expanded;
      },
      Depth() {
        return SymbolMap.Depth;
      },
      Uid() {
        return SymbolMap.Uid;
      },
      Parent() {
        return SymbolMap.Parent;
      },
      HasChildren() {
        return SymbolMap.HasChildren;
      },
      childrenProp() {
        return this.props.children;
      },
      treeHeight() {
        const { height, searchable, selectable } = this;
        if (searchable || selectable) {
          return Number(height) - 42;
        }
        return Number(height);
      },
    },
    mounted() {
      this.$nextTick(this.endLoading);
    },
    watch: {
      data: {
        handler(newData, oldData) {
          if (oldData) {
            this.clearReference();
          }
          this.initData(newData);
        },
        immediate: true,
      },
    },
    beforeDestroy() {
      this.clearReference();
    },
    methods: {
      isAllChecked() {
        const selectedCount = this.selectedNodes.size;
        // 选择了数据,且选择了所有数据,则是全选状态
        return selectedCount > 0 && selectedCount === this.DFSListData.length;
      },
      isAllIndeterminate() {
        const selectedCount = this.selectedNodes.size;
        // 选择了数据,但没有选择全部,则是半选状态
        return selectedCount > 0 && selectedCount < this.DFSListData.length;
      },
      handleBlur() {
        if (this.elFormItem && this.validateEvent) {
          this.elFormItem.validate('blur');
        }
      },
      // 清除引用
      clearReference() {
        const {
          DescendentsCheckedCount,
          DescendentList,
          Expanded,
          Depth,
          Uid,
          Parent,
          HasChildren,
        } = this;
        this.DFSListData.forEach(item => {
          [
            DescendentsCheckedCount,
            DescendentList,
            Expanded,
            Depth,
            Uid,
            Parent,
            HasChildren,
          ].forEach(key => {
            item[key] = null;
            delete item[key];
          });
        });
      },
      format(val) {
        const num = Number(val);
        if (Number.isNaN(num)) {
          return val;
        }
        return `${num}px`;
      },
      initData(data) {
        const {
          Expanded,
          Depth,
          Uid,
          Parent,
          childrenProp,
          HasChildren,
          isNodeHasChildren,
          DescendentList,
          DescendentsCheckedCount,
        } = this;
        let uid = 0;
        let maxDepth = 0;
        const hasChildrenNodes = [];
        const dfs = (tree, depth, result) => {
          tree.forEach(item => {
            // 层级
            item[Depth] = depth;
            // 全部后代
            item[DescendentList] = [];
            if (depth > maxDepth) maxDepth = depth;
            // 这里定义uid方便后续排序,节约时间
            item[Uid] = uid++;
            // 保存到打平的列表中
            result.push(item);
            // 存在子节点
            if (isNodeHasChildren(item)) {
              // 默认不展开
              item[Expanded] = false;
              // 标识有子节点
              item[HasChildren] = true;
              // 记录哪些有子节点
              hasChildrenNodes.push(item);
              // 保存父节点的引用,空间换时间
              item[childrenProp].forEach(innerItem => {
                // 保存父节点引用
                innerItem[Parent] = item;
              });
              // 遍历全部子节点
              dfs(item[childrenProp], depth + 1, result);
            }
          });
        };
        // 重置数据
        this.DFSListData = [];
        this.listData = [];
        this.expandedNodes = new Set();
        this.searchValue = '';
        this.searchData = [];
        this.selectedNodes = new Set();
        // 先全部遍历，设置成没有展开
        dfs(data, 1, this.DFSListData);
        const traverse = (tree, result) => {
          tree.forEach(item => {
            result.push(item);
            if (item[HasChildren]) {
              traverse(item[childrenProp], result);
            }
          });
        };
        // 对全部有子节点的节点做处理
        hasChildrenNodes.forEach(item => {
          const result = [];
          // 获取当前节点的全部后代节点
          traverse(item[childrenProp], result);
          // 保存后代元素
          item[DescendentList] = result;
          // 已选择的后代元素个数
          item[DescendentsCheckedCount] = 0;
        });
        // 保存原始数据
        this.listData = [...data];
        // console.log(this.data);
        // 默认展开所有
        if (this.defaultExpandAll) {
          // 展开每个节点，只对有子节点的节点做处理即可
          hasChildrenNodes.forEach(item => {
            item[Expanded] = true;
          });
          // 记录展开的节点
          this.expandedNodes = new Set(hasChildrenNodes);
          this.calculateExpandList();
        }
      },

      handleKeyup({ keyCode }) {
        if (keyCode === 13) {
          this.handleSearch(this.searchValue);
        }
      },
      handleSearch() {
        // eslint-disable-next-line
        const {
          Uid,
          searchValue,
          Expanded,
          getAncestors,
          expandedNodes,
          exactSearch,
          childrenProp,
        } = this;
        const searchData = this.DFSListData.filter(item => {
          if (exactSearch) {
            return this.renderLabel(item) === searchValue.trim();
          }
          return this.renderLabel(item).includes(searchValue.trim());
        });
        const result = new Set(searchData);
        searchData.forEach(item => {
          // 查询时，子节点默认展示
          if (this.childrenVisibleWhenSearch && Array.isArray(item[childrenProp])) {
            item[childrenProp].forEach(it => result.add(it));
          }
          const { ancestors } = getAncestors(item);
          // 对祖先节点进行展开处理
          [...ancestors].forEach(innerItem => {
            result.add(innerItem);
            innerItem[Expanded] = true;
            // 记录展开的节点
            expandedNodes.add(innerItem);
          });
        });
        // 排序并展示
        this.searchData = [...result].sort((a, b) => a[Uid] - b[Uid]);
        this.listData = this.searchData;
      },
      /** 获取父元素 */
      getParent(node) {
        return node[this.Parent];
      },
      /** 获取全部祖先元素,根节点在最后 */
      getAncestors(node) {
        const { getParent } = this;
        const ancestors = [];
        let parent = getParent(node);
        while (parent) {
          ancestors.push(parent);
          parent = getParent(parent);
        }
        return { ancestors, node };
      },
      /** 获取后代元素 */
      getDescendents(node) {
        return node[this.DescendentList];
      },
      /** 获取兄弟节点 */
      getSiblings(node) {
        const parent = this.getParent(node);
        // 第一层，没有父元素
        if (!parent) return this.data.filter(item => item !== node);
        // 不是第一层，则获取父节点的子节点并过滤
        return parent[this.childrenProp].filter(item => item !== node);
      },
      /** 是否是根节点 */
      isRootNode(node) {
        return node[this.Depth] === 1;
      },
      /** 是否存在子元素 */
      isNodeHasChildren(node) {
        const { childrenProp } = this;
        return Array.isArray(node[childrenProp]) && node[childrenProp].length > 0;
      },
      handleExpandClick(node, isExpanded, nodeId, event) {
        if (event) event.stopPropagation();
        const { Expanded, expandedNodes, getSiblings } = this;
        // 展开变折叠
        if (isExpanded) {
          node[Expanded] = false;
          expandedNodes.delete(node);
          this.$emit('node-collapse', node, nodeId);
        } else {
          // 折叠变展开，手风琴模式时，兄弟节点要折叠，如果是查询时，就不进行手风琴处理了
          if (this.accordion && this.searchData.length === 0) {
            getSiblings(node).forEach(item => {
              // 兄弟节点全部折叠
              item[Expanded] = false;
              expandedNodes.delete(item);
            });
          }
          node[Expanded] = true;
          expandedNodes.add(node);
          this.$emit('node-expand', node, nodeId);
        }
        this.calculateExpandList(this.searchData.length > 0);
      },
      /** 开始loading */
      startLoading() {
        this.loading = true;
      },
      /** 取消loading */
      endLoading() {
        this.loading = false;
      },
      /** 展开折叠操作，数据更新 */
      calculateExpandList(isSearch) {
        const {
          Expanded,
          Uid,
          HasChildren,
          expandedNodes,
          data,
          searchData,
          childrenProp,
          getAncestors,
        } = this;
        const showTreeData = isSearch ? [...searchData] : [...data];
        // 通过集合去重,根节点必显示
        const set = new Set(showTreeData);
        // 为什么要过滤？比如第一二三层的节点都是展开状态，此时将第一层节点折叠，其下的全部节点都应该不可见了
        // 所以要过滤出正真可见的节点！
        const visibleNodes = (isSearch ? [...showTreeData] : [...expandedNodes]).filter(item => {
          const { ancestors } = getAncestors(item);
          const { length } = ancestors;
          // 没有祖先,则其是根节点
          if (length === 0) return true;
          // 有祖先时,必须确保全部的祖先是展开状态,自己才能显示
          const isVisible = ancestors.every(innerItem => !!innerItem[Expanded]);
          if (!isVisible) {
            set.delete(item);
          }
          return isVisible;
        });
        // 然后把展开节点下面的子节点加入进来
        visibleNodes.forEach(item => {
          set.add(item);
          if (item[HasChildren] === true && item[Expanded]) {
            item[childrenProp].forEach(innerItem => set.add(innerItem));
          }
        });
        // 这里排序操作，是为了保持和DFS遍历的顺序一致
        // 主要是保持顺序啦
        const visibleListData = [...set].sort((a, b) => a[Uid] - b[Uid]);
        // this.startLoading();
        this.listData = visibleListData;
      },
      isNodeIndeterminate(node) {
        const { HasChildren, DescendentsCheckedCount, getDescendents } = this;
        if (!node[HasChildren]) return false;
        const descendents = getDescendents(node);
        const selectedCount = node[DescendentsCheckedCount];
        if (selectedCount > 0 && selectedCount < descendents.length) {
          return true;
        }
        return false;
      },
      isNodeExpanded(node) {
        return !!node[this.Expanded];
      },
      isNodeDisabled(node) {
        const { disabled } = this.props;
        if (typeof disabled === 'string') return !!node[disabled];
        return disabled(node);
      },
      isNodeSelected(node) {
        return this.selectedNodes.has(node);
      },
      // eslint-disable-next-line
      handleRadioClick(node, nodeId, event) {
        if (event) event.stopPropagation();
        if (this.isNodeDisabled(node)) return;
        const isIncluded = this.isNodeSelected(node);
        if (isIncluded) {
          if (this.radioToogleable) {
            this.selectedNodes = new Set();
            this.$emit('radio-change', node, nodeId, !isIncluded);
          }
        } else {
          this.selectedNodes = new Set([node]);
          this.$emit('radio-change', node, nodeId, !isIncluded);
        }
        this.$emit(
          'radio',
          node,
          nodeId,
          [...this.selectedNodes][0],
          [...this.selectedNodes][0]?.[this.nodeKey]
        );
      },
      // eslint-disable-next-line
      handleCheckboxClick(node, nodeId, event, emit) {
        if (event) event.stopPropagation();
        if (this.isNodeDisabled(node)) return;
        const {
          selectedNodes,
          getAncestors,
          isNodeSelected,
          getDescendents,
          isNodeHasChildren,
          DescendentsCheckedCount,
        } = this;
        const isIncluded = isNodeSelected(node);
        // 互相关联
        if (!this.checkStrictly) {
          const { ancestors } = getAncestors(node);
          const descendents = getDescendents(node);
          const selectedNodesSet = new Set([...selectedNodes]);
          // 选中变未选中
          if (isIncluded) {
            // 取消选中自己
            selectedNodesSet.delete(node);
            // 设置自己选中的后代为0
            node[DescendentsCheckedCount] = 0;
            // 取消选中后代
            descendents.forEach(item => {
              selectedNodesSet.delete(item);
              // 如果有子元素，也要清空其选中的后代数
              if (isNodeHasChildren(item)) {
                item[DescendentsCheckedCount] = 0;
              }
            });
            // 然后计算祖先元素的半选状态
            [node, ...ancestors].forEach(ancestor => {
              const currentDescendents = getDescendents(ancestor);
              const selectedDescendents = currentDescendents.filter(innerItem =>
                selectedNodesSet.has(innerItem)
              );
              ancestor[DescendentsCheckedCount] = selectedDescendents.length;
              // 没有选中后代或者选中的后代个数和后代总数不相等，那么要取消勾选
              if (
                selectedDescendents.length === 0 ||
                selectedDescendents.length < currentDescendents.length
              ) {
                selectedNodesSet.delete(ancestor);
              }
            });
            this.selectedNodes = new Set([...selectedNodesSet]);
          } else {
            // 未选中变选中
            // 选中自己
            selectedNodesSet.add(node);
            // 后代全部选中
            node[DescendentsCheckedCount] = descendents.length;
            // 选中后代
            descendents.forEach(item => {
              selectedNodesSet.add(item);
              // 如果有子元素，也要标识其是全部选中的
              if (isNodeHasChildren(item)) {
                item[DescendentsCheckedCount] = getDescendents(item).length;
              }
            });
            // 然后计算祖先元素的半选状态
            [node, ...ancestors].forEach(ancestor => {
              const currentDescendents = getDescendents(ancestor);
              const selectedDescendents = currentDescendents.filter(innerItem =>
                selectedNodesSet.has(innerItem)
              );
              ancestor[DescendentsCheckedCount] = selectedDescendents.length;
              // 如果后代全部选中，那么自己也应该被选中
              if (
                selectedDescendents.length > 0 &&
                selectedDescendents.length === currentDescendents.length
              ) {
                selectedNodesSet.add(ancestor);
                ancestor[DescendentsCheckedCount] = currentDescendents.length;
              }
            });
            this.selectedNodes = new Set([...selectedNodesSet]);
          }
        } else if (isIncluded) {
          // 不互相关联
          // 选中变未选中
          this.selectedNodes = new Set([...selectedNodes].filter(item => item !== node));
        } else {
          // 多选时,未选中变选中
          selectedNodes.add(node);
        }
        this.$forceUpdate();
        if (emit) {
          this.$emit('check', node, nodeId, {
            checkedNodes: this.getCheckedNodes(),
            checkedKeys: this.getCheckedKeys(),
            halfCheckedNodes: this.getHalfCheckedNodes(),
            halfCheckedKeys: this.getHalfCheckedKeys(),
          });
          this.$emit(
            'check-change',
            [...this.selectedNodes].map(item => item[this.nodeKey]),
            [...this.selectedNodes]
          );
        }
      },
      handleNodeContextmenu(node, nodeId, event) {
        this.$emit('node-contextmenu', node, nodeId, event);
      },
      handleNodeClick(node, nodeId) {
        const { Expanded, HasChildren } = this;
        this.$emit('node-click', node, nodeId);
        if (this.expandOnClickNode && node[HasChildren]) {
          this.handleExpandClick(node, node[Expanded], nodeId);
        }
        if (this.checkOnClickNode) {
          if (this.selectType === 'radio') {
            this.handleRadioClick(node, nodeId);
          } else if (this.selectType === 'checkbox') {
            this.handleCheckboxClick(node, nodeId, null, true);
          }
        }
      },
      handleSelectAll() {
        const { DescendentsCheckedCount, getDescendents } = this;
        // 全选时,则取消全选
        if (this.isAllChecked()) {
          this.selectedNodes = new Set();
          this.DFSListData.forEach(item => {
            item[DescendentsCheckedCount] = 0;
          });
        } else if (this.selectOnIndeterminate) {
          this.selectedNodes = new Set(this.DFSListData);
          this.DFSListData.forEach(item => {
            item[DescendentsCheckedCount] = getDescendents(item).length;
          });
        } else {
          this.selectedNodes = new Set();
          this.DFSListData.forEach(item => {
            item[DescendentsCheckedCount] = 0;
          });
        }
        this.$emit(
          'check-change',
          [...this.selectedNodes].map(item => item[this.nodeKey]),
          [...this.selectedNodes]
        );
      },
      renderLabel(node) {
        const { label } = this.props;
        if (typeof label === 'string') return node[label];
        return label(node);
      },
      calculatePaddingLeft(node) {
        const { HasChildren, Depth, indent } = this;
        if (node[HasChildren]) {
          return `${(node[Depth] - 1) * indent}px`;
        }
        if (node[Depth] < 3) return '0';
        return `${(node[Depth] - 2) * indent}px`;
      },
      /**
       * @description 若节点可被选择（即 selectType 为 checkbox），则返回目前被选中的节点所组成的数组
       * @param {boolean} leafOnly 是否只是叶子节点，默认值为 false
       * @param {boolean} includeHalfChecked 是否包含半选节点，默认值为 false
       */
      getCheckedNodes(leafOnly = false, includeHalfChecked = false) {
        const { HasChildren, DFSListData, isNodeIndeterminate, selectedNodes } = this;
        // 只包含叶子节点
        if (leafOnly === true) {
          return [...selectedNodes].filter(item => !item[HasChildren]);
        }
        // 还需要半选状态的节点
        if (includeHalfChecked === true) {
          return [...selectedNodes].concat(DFSListData.filter(item => isNodeIndeterminate(item)));
        }
        return [...selectedNodes];
      },
      /**
       * @description 设置目前勾选的节点
       * @param {Array} nodes 接收勾选节点数据的数组
       */
      setCheckedNodes(nodes) {
        const { nodeKey, DFSListData, selectType, selectedNodes } = this;
        if (selectType === 'radio') {
          if (nodes.length >= 1) {
            const [node] = nodes;
            const nodeId = node[nodeKey];
            const realNode = DFSListData.find(
              item => item[nodeKey] === nodeId && !selectedNodes.has(item)
            );
            // 如果存在该节点，且没有选中，则处理
            if (realNode) {
              this.handleRadioClick(node, nodeId, null);
            }
          }
        } else if (this.selectType === 'checkbox') {
          // 未选中，且存在的节点
          const unselectedNodes = nodes.filter(node =>
            DFSListData.find(item => item[nodeKey] === node[nodeKey] && !selectedNodes.has(item))
          );
          unselectedNodes.forEach(node => {
            this.handleCheckboxClick(node, node[nodeKey], null);
          });
        }
      },
      /**
       * @description 若节点可被选择（即 selectType 为 checkbox），则返回目前被选中的节点的 key 所组成的数组
       * @param {boolean} leafOnly 返回被选中的叶子节点的 keys
       */
      getCheckedKeys(leafOnly = false) {
        const { HasChildren, selectedNodes, nodeKey } = this;
        if (leafOnly === true) {
          return [...selectedNodes].filter(item => !item[HasChildren]).map(item => item[nodeKey]);
        }
        return [...selectedNodes].map(item => item[nodeKey]);
      },
      /**
       * @description 通过 keys 设置目前勾选的节点
       * @param {Array} keys 勾选节点的 key 的数组
       * @param {boolean} leafOnly 只设置叶子节点的选中状态
       */
      setCheckedKeys(keys, leafOnly) {
        const { nodeKey, selectType, HasChildren, DFSListData, selectedNodes } = this;
        if (selectType === 'radio') {
          if (keys.length >= 1) {
            const [nodeId] = keys;
            const node = this.DFSListData.find(
              item => item[nodeKey] === nodeId && !selectedNodes.has(item)
            );
            // 存在，且未选中，则处理
            if (node) {
              this.handleRadioClick(node, nodeId, null);
            }
          }
        } else if (selectType === 'checkbox') {
          if (leafOnly) {
            const unselectedLeafNodes = DFSListData.filter(
              // 过滤出存在、且未选中的叶子节点
              node => keys.includes(node[nodeKey]) && !node[HasChildren] && !selectedNodes.has(node)
            );
            unselectedLeafNodes.forEach(node => {
              this.handleCheckboxClick(node, node[nodeKey], null);
            });
          } else {
            const nodes = DFSListData.filter(
              // 过滤出存在、且未选中的节点
              node => keys.includes(node[nodeKey]) && !selectedNodes.has(node)
            );
            nodes.forEach(node => {
              this.handleCheckboxClick(node, node[nodeKey], null);
            });
          }
        }
      },
      /**
       * @description 通过 key / data 设置某个节点的勾选状态
       * @param {Object | string} nodeOrKey 勾选节点或节点的key
       * @param {boolean} checked 是否勾选节点
       */
      setChecked(nodeOrKey, checked) {
        const { nodeKey, DFSListData, selectType } = this;
        const nodeId = typeof nodeOrKey === 'object' ? nodeOrKey[nodeKey] : nodeOrKey;
        const activeNode = DFSListData.find(item => item[nodeKey] === nodeId);
        if (!activeNode) return;
        const isChecked = this.selectedNodes.has(activeNode);
        // 切换操作，选中变不选中或者不选中变选中
        if ((isChecked && !checked) || (!isChecked && checked)) {
          if (selectType === 'radio') {
            this.handleRadioClick(activeNode, nodeId, null);
          } else {
            this.handleCheckboxClick(activeNode, nodeId, null);
          }
        }
      },
      /** 复选框情况下，返回目前半选中的节点所组成的数组 */
      getHalfCheckedNodes() {
        if (this.selectType === 'checkbox') {
          return this.DFSListData.filter(node => this.isNodeIndeterminate(node));
        }
        return [];
      },
      /** 复选框情况下，回目前半选中的节点的 key 所组成的数组 */
      getHalfCheckedKeys() {
        const { nodeKey } = this;
        return this.getHalfCheckedNodes().map(node => node[nodeKey]);
      },
      /**
       * @description 根据 key 拿到 Tree 组件中的 node
       * @param {string} key 节点的 nodeKey
       */
      getNode(key) {
        return this.DFSListData.find(node => node[this.nodeKey] === key);
      },
    },
  };
</script>

<style lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .ykc-tree {
    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        height: 100px;
        width: 100px;
      }
      span {
        font-size: 20px;
        line-height: 10px;
        transform: scale(0.5, 0.5);
      }
    }
    display: block;
    border: 1px solid $_E2E0E0;
    box-sizing: border-box;
    height: 290px;
    padding-top: 40px;
    position: relative;
    .empty {
      font-size: 16px;
      position: absolute;
      height: 50%;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      top: 8px;
      display: flex;
      z-index: 10;
      justify-content: flex-start;
      align-items: center;
      padding: 0 12px;
      .select {
        .el-checkbox__label {
          padding-left: 8px;
          padding-right: 8px;
          color: $theme_primary;
          font-size: 12px;
          color: #000;
        }
      }
      .search {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-input {
          flex: 1;
          .el-input__inner {
            border: 1px solid #d7d7d7;
            display: block;
          }
        }
        .ykc-input.el-input {
          height: 24px;
        }
        .ykc-button.el-button {
          height: 24px;
          padding-top: 5px;
        }
      }
    }
    &-node {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 30px;
      border-bottom: 1px solid $_F6F7F8;
      box-sizing: border-box;
      padding-left: 12px;
      position: relative;
      // 根节点才有背景色
      &.is-root {
        background-color: $_F7FBFF;
      }
      .select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-right: 12px;
        .el-radio__label {
          display: none;
        }
      }
      .content {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon {
          color: $_4A4A4A;
          font-size: 16px;
          cursor: pointer;
        }
        .label {
          margin-left: 12px;
          font-size: 12px;
          // 叶子节点，没有展开图标，所以需要多余的缩进
          &.is-leaf {
            margin-left: 28px;
          }
        }
      }
    }
    // 多选的checkbox跟随主题色
    .el-checkbox {
      .el-checkbox__input.is-disabled .el-checkbox__inner {
        background: $_F6F7F8;
        border: 1px solid #cacaca;
      }
    }
    // 表头复选框半勾选状态，中间横线的样式
    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 4px;
    }
    // 复选框勾选状态，中间对勾的样式
    .el-checkbox .el-checkbox__inner::after {
      height: 6px;
      left: 3px;
      top: 0.5px;
    }
    // 表格复选框样式
    .el-checkbox .el-checkbox__inner {
      height: 12px;
      width: 12px;
      // 默认边框
      border: 1px solid $_9B9B9B;
      &:hover {
        border-color: $theme_primary;
      }
    }
    // 复选框勾选状态样式
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: $theme_primary;
      border-color: $theme_primary;
    }
    // 表头复选框勾选状态样式
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: $theme_primary;
      border-color: $theme_primary;
    }
    // 复选框勾选后取消勾选时的样式（is-focus）
    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: $theme_primary;
    }
  }
  // 运营商平台4.0 tree组件 样式
  body[ykc-ui='soui'] {
    .ykc-tree-v2,
    .ykc-tree {
      border: 1px solid rgba(13, 87, 235, 0.4);
      .header {
        .el-checkbox {
          .el-checkbox__input {
            .el-checkbox__inner {
              border: 1px solid #fff;
              background-color: transparent;
            }
          }
          .el-checkbox__input.is-indeterminate,
          .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: #00fff4;
              border-color: #00fff4;
            }
          }
          .el-checkbox__input.is-indeterminate {
            .el-checkbox__inner::before {
              background-color: #044f69;
            }
          }
          .el-checkbox__input.is-checked {
            .el-checkbox__inner::after {
              border-color: #044f69;
            }
          }
        }
        .search {
          .el-button.ykc-button:hover {
            background: #0056ff;
          }
          .ykc-input .el-input__inner {
            border: 1px solid rgba(13, 87, 235, 0.4);
          }
        }
      }
    }
    .ykc-tree {
      .ykc-list {
        .ykc-list-body {
          .ykc-tree-node {
            border-bottom: 1px solid rgba(13, 87, 235, 0.4);
            .el-checkbox__input {
              .el-checkbox__inner {
                border: 1px solid #fff;
                background-color: transparent;
              }
            }
            .el-checkbox__input.is-indeterminate,
            .el-checkbox__input.is-checked {
              .el-checkbox__inner {
                background-color: #00fff4;
                border-color: #00fff4;
              }
            }
            .el-checkbox__input.is-indeterminate {
              .el-checkbox__inner::before {
                background-color: #044f69;
              }
            }
            .el-checkbox__input.is-checked {
              .el-checkbox__inner::after {
                border-color: #044f69;
              }
            }
            .content {
              .label {
                color: #fff;
              }
              .el-icon-caret-right {
                color: #fff;
              }
              .el-icon-caret-bottom {
                color: #00fff4;
              }
            }
          }
          .ykc-tree-node.is-root {
            background: rgba(14, 95, 255, 0.2);
          }
          .ykc-tree-node.is-root:last-child {
            border-bottom: none;
          }
        }
      }
      .scrollbar.vertical {
        .hack {
          background: transparent;
          background-image: linear-gradient(
            90deg,
            rgba(90, 145, 255, 0.88) 0%,
            rgba(90, 145, 255, 0) 54%,
            rgba(90, 145, 255, 0.88) 100%
          );
        }
      }
    }
    .ykc-tree-v2 {
      background: transparent;
      .virtual-list {
        .el-tree-node {
          border-bottom: 1px solid rgba(13, 87, 235, 0.4);
          .el-tree-node__content {
            .el-icon-caret-right {
              color: #fff;
            }
            .expanded {
              color: #00fff4;
            }
            .el-checkbox {
              .el-checkbox__input {
                .el-checkbox__inner {
                  border: 1px solid #fff;
                  background-color: transparent;
                }
              }
              .el-checkbox__input.is-indeterminate,
              .el-checkbox__input.is-checked {
                .el-checkbox__inner {
                  background-color: #00fff4;
                  border-color: #00fff4;
                }
              }
              .el-checkbox__input.is-indeterminate {
                .el-checkbox__inner::before {
                  background-color: #044f69;
                }
              }
              .el-checkbox__input.is-checked {
                .el-checkbox__inner::after {
                  border-color: #044f69;
                }
              }
            }
            .el-tree-node__label {
              color: #fff;
            }
          }
        }
        .el-tree-node:focus > .el-tree-node__content,
        .el-tree-node__content:hover {
          background-color: transparent;
        }
      }
      .virtual-list {
        scrollbar-color: rgba(0, 169, 255, 0.1) transparent;
        scrollbar-width: thin;
      }
      // .virtual-list::-webkit-scrollbar-thumb {
      //   background: transparent;
      //   background-image: linear-gradient(
      //     90deg,
      //     rgba(90, 145, 255, 0.88) 0%,
      //     rgba(90, 145, 255, 0) 54%,
      //     rgba(90, 145, 255, 0.88) 100%
      //   );
      // }
    }
  }
</style>
