<!--
 * @Author: Daokai Huo
 * @Date: 2021-09-24 11:29:20
 * @LastEditTime: 2021-10-25 15:46:39
 * @Description: input组件
-->
<template>
  <el-autocomplete
    v-model="model"
    v-bind="$props"
    @blur="handleBlur"
    @focus="handleFocus"
    @input="handleChange"
    @clear="handleClear"
    class="ykc-autocomplete"
    :class="$slots.prepend && isFocus && 'focus-prepend'">
    <!-- 输入框前面增加内容 -->
    <template slot="prepend">
      <slot name="prepend"></slot>
    </template>
    <!-- 输入框后面增加内容 -->
    <template slot="append">
      <slot name="append"></slot>
    </template>
  </el-autocomplete>
</template>
<script>
  import xss from './xss';

  export default {
    name: 'YkcAutocomplete',
    props: {
      'fetch-suggestions': {
        type: Function,
        default: () => {},
      },
      // 绑定值
      value: [String, Number],
      // 最大输入长度
      maxlength: [Number, String],
      // 最小输入长度
      minlength: [Number, String],
      // 输入框占位文本
      placeholder: {
        type: String,
        default: '请输入',
      },
      // 是否有清除按钮
      clearable: {
        type: Boolean,
        default: false,
      },
      // input禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      // 是否只读
      readonly: {
        type: Boolean,
        default: false,
      },
      // 自动获取焦点
      autofocus: {
        type: Boolean,
        default: false,
      },

      // 输入时是否触发表单校验
      validateEvent: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isFocus: false,
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', xss.process(val.trim()));
        },
      },
    },
    methods: {
      /**
       * 在 Input 失去焦点时触发
       */
      handleBlur(evt) {
        this.isFocus = false;
        this.$emit('blur', evt);
      },
      /**
       * 在 Input 获得焦点时触发
       */
      handleFocus(evt) {
        this.isFocus = true;
        this.$emit('focus', evt);
      },
      /**
       * 在 Input 值改变时触发
       */
      handleChange(evt) {
        this.$emit('change', evt);
      },
      /**
       * 在点击由 clearable 属性生成的清空按钮时触发
       */
      handleClear() {
        this.$emit('clear');
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
</style>
