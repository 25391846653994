<!--
 * @Descripttion: table组件
 * @Author: 汪佳彪
 * @date: 2021-10-09 17:01
-->
<script>
  import YkcIcon from '@/components/ykc-ui/icon';
  import YkcButton from '@/components/ykc-ui/button';
  import noDataImg from '@/components/ykc-ui/assets/no-data.png';
  /**
   * @description 克隆给定对象，但忽略部分属性
   * @param {object} obj 给定对象
   * @param {array} keys 忽略的属性数组
   * @returns {object}
   */
  function omit(obj, keys) {
    return Object.keys(obj).reduce((res, cur) => {
      if (!keys.includes(cur)) {
        res[cur] = obj[cur];
      }
      return res;
    }, {});
  }

  const buttonKeys = ['id', 'text', 'render', 'handleClick'];

  export default {
    name: 'YkcTable',
    components: { YkcButton, YkcIcon },
    props: {
      /** 头部标题 */
      title: {
        type: String,
        default: '',
      },
      /** 头部按钮组 */
      headerButtons: {
        type: Array,
        default: () => [],
      },
      /** 头部按钮组,每个按钮的默认属性 */
      headerButtonDefaultProps: {
        type: Object,
        default: () => ({}),
      },
      /** 配置button的属性应该用哪个key */
      buttonPropConfig: {
        type: Object,
        default: () => ({}),
        validator(val) {
          if (Object.keys(val).length > 0) {
            return buttonKeys.some(key => Object.keys(val).includes(key)); // 至少存在数组['id', 'text', 'render', 'handleClick']中的其中一个
          }
          return true;
        },
      },
      data: {
        type: Array,
        default: () => [],
      },
      /** 是否展示复选框 */
      selectable: {
        type: Boolean,
        default: false,
      },
      /** 当前行是否可勾选 */
      rowSelectable: {
        type: Function,
        default: () => true,
      },
      /**
       * 在多选表格中，当仅有部分行被选中时，点击表头的多选框时的行为。
       * 若为 true，则选中所有行；若为 false，则取消选择所有行
       */
      selectOnIndeterminate: {
        type: Boolean,
        default: false,
      },
      /** 单元格空数据时显示的文本内容 */
      emptyCellText: {
        type: String,
        default: '——',
      },
      /** 空数据时显示的文本内容，也可以通过 `slot="empty"` 设置 */
      emptyText: {
        type: String,
        default: '暂无数据',
      },
      /** 空数据时展示的图片地址 */
      emptyImageURL: {
        type: String,
        default: noDataImg,
      },
      // 没有分页器时候的部分布局被遮挡
      hasPageNation: {
        type: Boolean,
        default: true,
      },
      /** 传入内部 el-table 的属性 */
      tableConfig: {
        type: Object,
        default: () => ({}),
        validator(val) {
          const keys = Object.keys(val);
          if (keys.length > 0) {
            // 如果传入el-table部分属性
            const allowedKeys = [
              'stripe',
              'border',
              'height',
              'maxHeight',
              'rowClassName',
              'cellClassName',
              'headerRowClassName',
              'headerCellClassName',
              // 'tooltipEffect', // 表格内容的title自定义，不需要tooltip了
              // 表尾统计行
              'showSummary',
              'spanMethod',
              'summaryMethod',
              'rowKey',
              'treeProps',
            ];
            return keys.every(key => allowedKeys.includes(key));
          }
          return true;
        },
      },
      /** 表格列,不包含选择列和操作列 */
      columns: {
        type: Array,
        default: () => [],
        validator(val) {
          if (val.length > 0) {
            const allowedKeys = [
              // 'column-key', // filter-change 需要
              'id',
              'label',
              'prop',
              'width',
              'minWidth',
              'fixed',
              'showOverflowTooltip', // 不需要el-table自带的tooltip，要定制
              'align',
              'headerAlign',
              'className',
              'labelClassName',
              'scopedSlots', // 插槽不能忘了
            ];
            return val.every(column => {
              if (Object.keys(column).length > 0) {
                if ('scopedSlots' in column) {
                  if (typeof column.scopedSlots !== 'object') return false;
                  return Object.keys(column.scopedSlots).every(key =>
                    ['default', 'defaultTitle', 'header', 'headerTitle'].includes(key)
                  );
                }
                return Object.keys(column).every(key => allowedKeys.includes(key));
              }
              return true;
            });
          }
          return true;
        },
      },
      /** 每列的键名，默认为 `prop` */
      columnKeyProp: {
        type: String,
        default: 'prop',
      },
      /** 操作列该如何fixed，可选值有 auto 和 right */
      operateFixedType: {
        type: String,
        default: 'auto',
        validator(val) {
          return ['auto', 'right'].includes(val);
        },
      },
      /** 操作列，表头显示的标题 */
      operateLabel: {
        type: [String, Function],
        default: '操作',
      },
      /** 操作列数据行的按钮组,可以是数组和函数 */
      operateButtons: {
        type: [Array, Function],
        default: () => [],
      },
      /**
       * 操作列按钮很多时,超过某个数目,超出的按钮显示在弹出层
       * 此处通过属性定义此数目
       */
      operateButtonsSplitCount: {
        type: Number,
        default: 3,
        validator: val => {
          return val > 1;
        },
      },
      /** 操作列按钮默认属性 */
      operateButtonDefaultProps: {
        type: Object,
        default: () => ({ type: 'text' }),
      },
      /** 操作列,更多操作在hover时的tooltip属性 */
      operateTooltipProps: {
        type: Object,
        default: () => ({
          content: '更多操作',
          placement: 'top',
          popperClass: 'operate-column-tooltip-popper',
        }),
      },
      /** 操作列,可弹出按钮组的图标按钮的图标定义 */
      operateButtonExtraIcon: {
        type: String,
        default: 'points',
      },
      /** 操作列宽度,可选值为 auto 和 数字字符串 */
      operateWidth: {
        type: String,
        default: 'auto',
        validator(val) {
          if (val && val !== 'auto') {
            return Number(val) > 0;
          }
          return true;
        },
      },
    },
    data() {
      return {
        operateColumnWidth: this.operateWidth === 'auto' ? '' : this.operateWidth,
        extraButton: null,
        showEmpty: false,
      };
    },
    watch: {
      data: {
        deep: true,
        immediate: true,
        handler(newValue) {
          this.showEmpty = !newValue.length;
        },
      },
    },
    computed: {
      operateable() {
        const { operateButtons } = this;
        if (typeof operateButtons === 'function') return true;
        return operateButtons.length > 0;
      },
      headerVisible() {
        return (
          this.$slots.title ||
          this.$slots.headerButtons ||
          this.title ||
          this.headerButtons.length > 0
        );
      },
      computedButtonConfig() {
        const obj = buttonKeys.reduce((res, cur) => {
          res[cur] = cur;
          return res;
        }, {});
        return {
          ...obj,
          ...this.buttonPropConfig,
        };
      },
      operateColumnFixedValue() {
        if (this.operateFixedType === 'auto') {
          return this.columns.some(column => column.fixed === 'right') ? 'right' : false;
        }
        return this.operateFixedType;
      },
    },
    methods: {
      /** 获取点击上一次点击的extra按钮 */
      getExtraButton() {
        return this.extraButton;
      },
      /**
       * @description 用于多选表格，清空用户的选择
       */
      clearSelection() {
        if (this.$refs.Table && this.selectable) {
          this.$refs.Table.clearSelection();
        }
      },
      /**
       * @description 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
       */
      toggleRowSelection(row, selected) {
        if (this.$refs.Table && this.selectable) {
          this.$refs.Table.toggleRowSelection(row, selected);
        }
      },
      /**
       * @description 用于多选表格，切换所有行的选中状态
       */
      toggleAllSelection() {
        if (this.$refs.Table && this.selectable) {
          this.$refs.Table.toggleAllSelection();
        }
      },
      /**
       * @description 对 Table 进行重新布局。当 Table 或其祖先元素由隐藏切换为显示时，可能需要调用此方法
       */
      doLayout() {
        if (this.$refs.Table) {
          this.$refs.Table.doLayout();
        }
      },
      /**
       * @description 头部标题
       */
      renderTitle() {
        const { title, $slots } = this;
        // 使用title插槽
        if ($slots.title) return $slots.title;
        return <span class="ykc-table-header-title">{title}</span>;
      },
      /**
       * @description 渲染头部单个按钮
       */
      renderHeaderButton(h, button, index) {
        const {
          computedButtonConfig: { id, text, render, handleClick },
          headerButtonDefaultProps,
        } = this;
        const buttonRender = button[render];
        if (buttonRender && typeof buttonRender === 'function') {
          // 需要注入 h 函数，渲染全权交给外面处理
          return buttonRender(h, button, this);
        }
        const buttonId = button[id];
        const buttonText = button[text];
        const buttonHandleClick = button[handleClick];
        const attrs = {
          ...headerButtonDefaultProps,
          ...omit(button, buttonKeys),
        };
        return (
          <ykc-button
            key={buttonId || index}
            // 使用排除buttonKeys之外的属性，如果出现属性冲突，则修改buttonPropConfig
            {...{ attrs }}
            onClick={() => {
              if (buttonHandleClick && typeof buttonHandleClick === 'function') {
                buttonHandleClick(button, index, this);
              } else {
                this.$emit('header-button-click', button, index, this);
              }
            }}>
            {buttonText}
          </ykc-button>
        );
      },
      /**
       * @description 头部按钮组
       */
      renderHeaderButtons(h) {
        const { headerButtons, $slots } = this;
        // 使用buttons插槽
        if ($slots.headerButtons) return $slots.headerButtons;
        if (headerButtons.length === 0) return null;
        return (
          <div class="ykc-table-header-buttons">
            {headerButtons.map((button, index) => this.renderHeaderButton(h, button, index))}
          </div>
        );
      },
      /**
       * @description 头部
       */
      renderHeader(h) {
        if (!this.headerVisible) return null;
        return (
          <div class="ykc-table-header">
            {this.renderTitle()}
            {this.renderHeaderButtons(h)}
          </div>
        );
      },
      // /**
      //  * @description 渲染操作列的按钮
      //  */
      // renderOperateButton() {
      // },
      /**
       * @description 表格
       */
      renderTable(h) {
        const {
          data,
          columns,
          selectable,
          rowSelectable,
          operateable,
          operateColumnFixedValue,
          operateColumnWidth,
          operateLabel,
          operateButtons,
          operateButtonsSplitCount,
          operateTooltipProps,
          operateButtonDefaultProps,
          operateButtonExtraIcon,
          selectOnIndeterminate,
          columnKeyProp,
          tableConfig,
          // emptyText,
          $slots,
        } = this;
        const tableClass = {
          // 偶数数据情况下，表格有下边框
          'ykc-table-bottom-border': data.length > 0 && data.length % 2 === 0,
        };
        const tableListeners = [
          'select',
          'select-all',
          'selection-change',
          'cell-mouse-enter',
          'cell-mouse-leave',
          'cell-click',
          'cell-dblclick',
          'row-click',
          'row-contextmenu',
          'row-dblclick',
          'header-click',
          'header-contextmenu',
        ].reduce((res, cur) => {
          res[cur] = (...args) => {
            // 可能需要对选择列和操作列做过滤
            this.$emit(cur, ...args);
          };
          return res;
        }, {});
        return (
          // 字体 /* 细 Regular */
          <el-table
            ref="Table"
            tooltipEffect="dark"
            data={data}
            class={tableClass}
            {...{ attrs: tableConfig, on: tableListeners }}
            selectOnIndeterminate={selectable && selectOnIndeterminate}>
            {
              /* 54 = 规范定的左侧安全距离 30px + checkbox.width 12px + 规范定的距离 12px */
              selectable && (
                <el-table-column
                  width="54"
                  type="selection"
                  selectable={rowSelectable}
                  labelClassName="ykc-table-first-column-label"
                  className="ykc-table-first-column el-table-column--selection" // 自定义className会替换默认的class,故加上el-table-column--selection
                ></el-table-column>
              )
            }
            {columns.map(column => {
              const columnId = column[columnKeyProp];
              const columnAttrs = {
                ...omit(column, [columnKeyProp]),
                prop: columnId,
              };
              const userDefineScopedSlots = column.scopedSlots;
              const scopedSlots = {
                header: scoped => {
                  const defaultLabel = scoped.column.label;
                  let child = defaultLabel;
                  let title = defaultLabel;
                  if (userDefineScopedSlots && userDefineScopedSlots.header) {
                    child = userDefineScopedSlots.header(scoped);
                  }
                  if (userDefineScopedSlots && userDefineScopedSlots.headerTitle) {
                    title = userDefineScopedSlots.headerTitle(scoped);
                  }
                  return (
                    <div class="ykc-table-label-wrap" title={title}>
                      {child}
                    </div>
                  );
                },
                default: scoped => {
                  const rowData = scoped.row[columnId];
                  let child = rowData || this.emptyCellText;
                  let title = rowData || this.emptyCellText;
                  if (rowData === 0) {
                    child = 0;
                    title = 0;
                  }
                  if (userDefineScopedSlots && userDefineScopedSlots.default) {
                    child = userDefineScopedSlots.default(scoped);
                  }
                  if (userDefineScopedSlots && userDefineScopedSlots.defaultTitle) {
                    title = userDefineScopedSlots.defaultTitle(scoped);
                  }
                  return <div class="ykc-table-cell-wrap">{child}</div>;
                },
              };
              return (
                <el-table-column
                  key={columnId}
                  columnKey={columnId}
                  scopedSlots={scopedSlots}
                  showOverflowTooltip={true}
                  {...{ attrs: columnAttrs }}
                />
              );
            })}
            {this.$slots.default}
            {
              /** 操作列 */
              operateable && (
                <el-table-column
                  // 宽度需要自动计算,故此处放在data中,并在updated更新该值
                  width={operateColumnWidth}
                  fixed={operateColumnFixedValue}
                  className="ykc-table-operate-column"
                  labelClassName="ykc-table-operate-column-label"
                  scopedSlots={{
                    default: ({ row, column, $index }) => {
                      if (typeof operateButtons === 'function') {
                        return operateButtons(h, { row, column, $index }, this);
                      }
                      const {
                        computedButtonConfig: { id, text, render, handleClick },
                      } = this;
                      let visibleButtons = operateButtons;
                      // 如果超出规定的个数operateButtonsSplitCount,则截取前面的部分显示在表格中,后面的部分通过弹出层显示
                      if (operateButtons.length > operateButtonsSplitCount + 1) {
                        visibleButtons = operateButtons.slice(0, operateButtonsSplitCount);
                        const invisibleButtons = operateButtons.slice(operateButtonsSplitCount);
                        const handleMouseover = ({ target }) => {
                          const { getExtraButton } = this;
                          const extraButton = getExtraButton();
                          if (extraButton) {
                            // eslint-disable-next-line no-underscore-dangle
                            const popperVm = extraButton.__vue__.$parent;
                            const popperEl = popperVm.$refs.popper;
                            const td = extraButton.closest(
                              'td.ykc-table-operate-column.el-table__cell'
                            );
                            if (
                              target !== popperEl &&
                              !popperEl.contains(target) &&
                              !td.contains(target)
                            ) {
                              popperVm.showPopper = false;
                            }
                          }
                        };
                        visibleButtons.push({
                          // 渲染可弹出按钮组的按钮
                          [render]: () => {
                            return (
                              <el-tooltip
                                {...{ attrs: operateTooltipProps }}
                                class="operate-column-tooltip-wrapper">
                                <span slot="content" class="operate-column-tooltip-popper-content">
                                  {operateTooltipProps.content}
                                </span>
                                <el-popover
                                  popperClass="operate-column-dropdown-wrapper"
                                  visibleArrow={false}
                                  trigger="click"
                                  onShow={() => {
                                    console.log('onShow');
                                    document.addEventListener('mouseover', handleMouseover);
                                  }}
                                  onHide={() => {
                                    console.log('onHide');
                                    document.removeEventListener('mouseover', handleMouseover);
                                  }}
                                  placement="bottom">
                                  {invisibleButtons.map((button, index) => {
                                    const buttonRender = button[render];
                                    if (buttonRender && typeof buttonRender === 'function') {
                                      return buttonRender(h, button, { row, column, $index }, this);
                                    }
                                    const buttonId = button[id];
                                    const buttonText = button[text];
                                    const buttonHandleClick = button[handleClick];
                                    const attrs = {
                                      ...operateButtonDefaultProps,
                                      ...omit(button, buttonKeys),
                                    };
                                    return (
                                      <ykc-button
                                        key={buttonId || index}
                                        {...{ attrs }}
                                        // eslint-disable-next-line
                                        onClick={event => {
                                          // eslint-disable-next-line
                                          const buttonVm = event.target.closest('button').__vue__;
                                          let vm = buttonVm;
                                          while (vm) {
                                            if (vm.$options.name === 'ElPopover') {
                                              // 点击弹出的按钮,应该关闭弹出层
                                              vm.showPopper = false;
                                              break;
                                            }
                                            vm = vm.$parent;
                                          }
                                          if (
                                            buttonHandleClick &&
                                            typeof buttonHandleClick === 'function'
                                          ) {
                                            buttonHandleClick(
                                              button,
                                              { row, column, $index },
                                              this
                                            );
                                          } else {
                                            this.$emit(
                                              'row-button-click',
                                              button,
                                              { row, column, $index },
                                              this
                                            );
                                          }
                                        }}>
                                        {buttonText}
                                      </ykc-button>
                                    );
                                  })}
                                  <ykc-icon
                                    slot="reference"
                                    name={operateButtonExtraIcon}
                                    onClick={({ target }) => {
                                      this.extraButton = target;
                                    }}></ykc-icon>
                                </el-popover>
                              </el-tooltip>
                            );
                          },
                        });
                      }
                      return visibleButtons.map((button, index) => {
                        const buttonRender = button[render];
                        if (buttonRender && typeof buttonRender === 'function') {
                          return buttonRender(h, button, { row, column, $index }, this);
                        }
                        const buttonId = button[id];
                        const buttonText = button[text];
                        const buttonHandleClick = button[handleClick];
                        const attrs = { ...operateButtonDefaultProps, ...omit(button, buttonKeys) };
                        return (
                          <ykc-button
                            key={buttonId || index}
                            // 使用排除buttonKeys之外的属性，如果出现属性冲突，则修改buttonPropConfig
                            {...{ attrs }}
                            onClick={() => {
                              if (buttonHandleClick && typeof buttonHandleClick === 'function') {
                                buttonHandleClick(button, { row, column, $index }, this);
                              } else {
                                this.$emit(
                                  'row-button-click',
                                  button,
                                  { row, column, $index },
                                  this
                                );
                              }
                            }}>
                            {buttonText}
                          </ykc-button>
                        );
                      });
                    },
                    header: ({ column, $index }) => {
                      if (typeof operateLabel === 'function') {
                        return operateLabel(h, { column, $index }, this);
                      }
                      return operateLabel;
                    },
                  }}
                />
              )
            }
            {
              /** empty插槽 */ <template slot="empty">
                {this.showEmpty ? (
                  $slots.empty || (
                    <div class="ykc-table-empty">
                      <img src={this.emptyImageURL} alt="table-empty" />
                      <span>{this.emptyText}</span>
                    </div>
                  )
                ) : (
                  <span></span>
                )}
              </template>
            }
          </el-table>
        );
      },
      /**
       * @description 分页器
       */
      renderPagination() {
        if (this.data.length === 0) return null;
        if (!this.$slots.pagination) return null;
        return <div class="ykc-table-pagination-wrapper">{this.$slots.pagination}</div>;
      },
      calculateColumnWidth() {
        this.$nextTick(() => {
          if (this.operateable && this.operateWidth === 'auto') {
            const elements = [
              ...this.$el.querySelectorAll(
                '.el-table .el-table__row .el-table__cell.ykc-table-operate-column .cell'
              ),
            ];
            const maxWidth = Math.max(...elements.map(el => el.offsetWidth));
            if (maxWidth !== this.operateColumnWidth) this.operateColumnWidth = maxWidth;
          }
        });
      },
    },
    render(h) {
      return (
        <div class="ykc-table">
          {this.renderHeader(h)}
          {this.renderTable(h)}
          {!this.hasPageNation && <div style={{ height: '50px' }}></div>}
          {this.renderPagination(h)}
        </div>
      );
    },
    created() {
      const { columns, selectable, operateable } = this;
      // 没有复选框列时，对第一列做样式处理
      if (!selectable) {
        const firstColumnInView =
          columns.filter(column => ['left', true].some(item => column.fixed === item))[0] ||
          columns[0];
        if (firstColumnInView) {
          ['className', 'labelClassName'].forEach(key => {
            const extraClass =
              key === 'className' ? 'ykc-table-first-column' : 'ykc-table-first-column-label';
            this.$set(
              firstColumnInView,
              key,
              firstColumnInView[key] ? `${firstColumnInView[key]} ${extraClass}` : extraClass
            );
          });
        }
      }
      // 没有操作列时，对最后一列做样式处理
      if (!operateable) {
        const lastColumnInView =
          columns.filter(column => column.fixed === 'right')[0] || columns.slice(-1)[0];
        if (lastColumnInView) {
          ['className', 'labelClassName'].forEach(key => {
            const extraClass =
              key === 'className' ? 'ykc-table-last-column' : 'ykc-table-last-column-label';
            this.$set(
              lastColumnInView,
              key,
              lastColumnInView[key] ? `${lastColumnInView[key]} ${extraClass}` : extraClass
            );
          });
        }
      } else {
        const lastColumnInView =
          columns.filter(column => column.fixed === 'right')[0] || columns.slice(-1)[0];
        if (lastColumnInView) {
          ['className', 'labelClassName'].forEach(key => {
            const extraClass =
              key === 'className'
                ? 'ykc-table-last-column operate-column-show'
                : 'ykc-table-last-column-label  operate-column-show';
            this.$set(
              lastColumnInView,
              key,
              lastColumnInView[key] ? `${lastColumnInView[key]} ${extraClass}` : extraClass
            );
          });
        }
      }
    },
    updated() {
      this.calculateColumnWidth();
    },
    activated() {
      this.calculateColumnWidth();
    },
    mounted() {
      this.calculateColumnWidth();
    },
    beforeDestroy() {
      this.extraButton = null;
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
