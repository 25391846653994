<!--
* @Author: Yanxia Wan
* @Date: 2021-10-30
* @Description: 下拉框组件(联想下拉)
-->
<template>
  <div
    class="ykc-dropdown"
    :class="{
      remote: remote,
      multiple: multiple,
      'multi-line': isMultiLine && multiple,
    }">
    <div class="ykc-dropdown-box" :class="$slots.prepend && isFocus && 'focus-prepend'">
      <!-- 前置元素 -->
      <div class="el-input-group__prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <el-select
        v-model="model"
        ref="select"
        :isObject="isObject"
        :multiple="multiple"
        :placeholder="placeholder"
        :filterable="filterable"
        :clearable="clearable"
        :disabled="disabled"
        :no-match-text="noMatchText"
        :no-data-text="noDataText"
        :multiple-limit="multipleLimit"
        :popper-class="popperClass"
        :remote="remote"
        :remote-method="customRemoteMethod || remoteMethod"
        :reserve-keyword="reserveKeyword"
        :loading="loading"
        @change="handleChange"
        @focus="handleFocus"
        @blur.native.capture="handleBlur"
        @visible-change="visibleChange"
        @clear="handleClear">
        <el-option
          v-for="(item, index) in remoteData"
          :key="index"
          :label="getLabel(item)"
          :value="getValue(item)"
          :disabled="item.disabled"></el-option>
      </el-select>
      <!-- 后置元素 -->
      <div class="el-input-group__append" ref="slotAppend" v-if="$slots.append">
        <slot name="append"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YkcDropdown',
    props: {
      /**
       * 据源默认选中的value的值 可能是Array 可能是String/Number 为数据的value值
       * 多选时是Array ['1', '2']
       * 单选时是String/Number '1'
       */
      value: {}, // 即数据源默认选中的value的值
      multiple: {
        // 是否多选，默认false，非必传。
        type: Boolean,
        default: false,
      },
      filterable: {
        // 是否为可搜索,默认true，非必传。
        type: Boolean,
        default: true,
      },
      remote: {
        // 是否为联想,默认false，非必传。
        type: Boolean,
        default: false,
      },
      data: {
        // 数据源 [{value:'xx',label:'xxx',disabled:true||false}] 如果key不是value和label 则参照configSet参数说明
        type: Array,
        default: () => [],
      },
      configSet: {
        // 配置项 数据源与默认数据源不匹配时使用 如所传数据源[{id:'xx', name:'xxx', disabled:true||false}] 则该参数可以如此传递{label: 'name', value: 'id'}
        type: Object,
        default: () => ({
          label: 'name',
          value: 'id',
        }),
      },
      placeholder: {
        // 选择框提示文字，默认"请选择"，非必传。
        type: String,
        default: '请选择',
      },
      disabled: {
        // 是否禁用，默认false，非必传。
        type: Boolean,
        default: false,
      },
      clearable: {
        // 是否可以清空 即是否出现x清除所选数据
        type: Boolean,
        default: true,
      },
      isMultiLine: {
        // 正常多选下拉是否支持多行显示
        type: Boolean,
        default: false,
      },
      popperClass: {
        // 下拉框的类名
        type: String,
        default: '',
      },
      isObject: {
        // 通过change方法获取的值是object还是value单个值
        type: Boolean,
        default: false,
      },
      multipleLimit: {
        // 多选时用户最多可以选择的项目数，为 0 则不限制 多选下拉使用
        type: Number,
        default: 0,
      },
      noMatchText: {
        // 搜索条件无匹配时显示的文字 联想框使用
        type: String,
        default: '无匹配数据',
      },
      noDataText: {
        // 没有搜索下拉数据的显示文字 联想框使用
        type: String,
        default: '无数据',
      },
      reserveKeyword: {
        // 多选且可搜索时，是否在选中一个选项后保留当前的搜索关键词
        type: Boolean,
        default: true,
      },
      searchMaxLength: {
        // 联想 渲染的最大条数
        type: Number,
        default: 1000,
      },
      // 自定义的远程方法
      customRemoteMethod: {
        type: Function,
        require: false,
      },
      // inputMaxLength: { // 输入最大限制
      //     type: Number,
      //     default: 100
      // }
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    watch: {
      value: {
        handler() {
          this.dealData();
        },
        deep: true, // 是否深度监听
      },
      data: {
        handler() {
          this.dealData();
        },
        deep: true, // 是否深度监听
      },
    },
    data() {
      return {
        timeout: null, // 大数据搜索使用定时器
        loading: false, // 大数据搜索时使用
        remoteData: [],
        isFocus: false,
      };
    },
    mounted() {
      this.dealData();
    },
    methods: {
      /**
       * 获取label的配置
       */
      getLabel(item) {
        return item ? item[this.configSet.label] : '';
      },
      /**
       * 获取value的配置
       */
      getValue(item) {
        return item ? item[this.configSet.value] : '';
      },
      /**
       * 获取截取的数组
       */
      slice(array, start, end) {
        let length = array == null ? 0 : array.length;
        if (!length) {
          return [];
        }
        start = start == null ? 0 : start;
        end = end === undefined ? length : end;
        if (start < 0) {
          start = -start > length ? 0 : length + start;
        }
        end = end > length ? length : end;
        if (end < 0) {
          end += length;
        }
        length = start > end ? 0 : (end - start) >>> 0; // eslint-disable-line no-bitwise
        start >>>= 0; // eslint-disable-line no-bitwise
        let index = -1;
        const result = new Array(length);
        while (++index < length) {
          result[index] = array[index + start];
        }
        return result;
      },

      /**
       * 从数组的起始元素开始提取 N 个元素。
       */
      take(array, n = 1) {
        if (!(array != null && array.length)) {
          return [];
        }
        const arrayValue = this.slice(array, 0, n < 0 ? 0 : n);
        return arrayValue;
      },
      /**
       * 远程搜索方法
       * @param query 输入的数据
       */
      remoteMethod(query) {
        // 防止多次刷无用的数据，所以先判断有没有定时器，有则清一下
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        const qur = query || (this.multiple ? this.$refs.select.previousQuery : '');
        if (qur) {
          this.loading = true;
          this.remoteData = [];
          this.timeout = setTimeout(() => {
            // 匹配数据里相匹配的数据（忽略大小写）
            this.remoteData = this.take(
              this.data.filter(
                item =>
                  (item[this.configSet.label] || '').toLowerCase().indexOf(qur.toLowerCase()) > -1
              ),
              this.searchMaxLength
            );
            // 关闭loading
            this.loading = false;
          }, 50);
        } else {
          this.remoteData = this.take(this.data, this.searchMaxLength);
        }
      },
      /**
       * 选中值发生变化时触发，并且传递新值
       */
      handleChange(evt) {
        // this.isFocus = true;
        let res = evt;
        // 联想下拉多选时，显示下拉框时，回填选中项文字
        if (this.multiple) {
          this.$nextTick(() => {
            this.$refs.select.selected = evt.map(item => {
              const idArray = { [this.configSet.value]: item };
              const newArray = []; // 新数组
              let j = 0;
              Object.keys(this.data).forEach(i => {
                if (idArray.id instanceof Array && idArray.id.length >= 2) {
                  idArray.id.forEach(e => {
                    if (this.data[i].id === e) {
                      newArray[j++] = this.data[i];
                    }
                  });
                } else if (this.data[i].id === idArray.id) {
                  newArray[j++] = this.data[i];
                }
              });
              const currentLabels = newArray[0] || {};
              const obj = {
                value: item,
                currentLabel: currentLabels[this.configSet.label],
              };
              return { ...obj };
            });
          });
        } else {
          this.isFocus = true;
        }
        if (this.multiple && this.isObject) {
          res = evt.map(item => {
            const idArray = { [this.configSet.value]: item };
            const newArray = []; // 新数组
            let j = 0;
            Object.keys(this.data).forEach(i => {
              if (idArray.id.length >= 2) {
                idArray.id.forEach(e => {
                  if (this.data[i].id === e) {
                    newArray[j++] = this.data[i];
                  }
                });
              } else if (idArray.id.length < 2) {
                if (this.data[i].id === idArray.id) {
                  newArray[j++] = this.data[i];
                }
              }
            });
            const obj = newArray[0];
            return { ...obj };
          });
        } else if (!this.multiple && this.isObject) {
          // const idArray = { [this.configSet.value]: evt };
          // const newArray = []; // 新数组
          // let j = 0;
          // console.log('idArray', idArray);
          // Object.keys(this.data).forEach(i => {
          //   if (idArray.id.length >= 2) {
          //     idArray.id.forEach(e => {
          //       if (this.data[i].id === e) {
          //         newArray[j++] = this.data[i];
          //       }
          //     });
          //   } else if (idArray.id.length < 2) {
          //     if (this.data[i].id === idArray.id) {
          //       newArray[j++] = this.data[i];
          //     }
          //   }
          // });
          // res = newArray;
          const [result] = this.data.filter(item => item[this.configSet.value] === evt);
          res = result;
        }
        this.$emit('change', res);
      },
      /**
       * 透传focus方法
       */
      handleFocus(evt) {
        this.isFocus = true;
        this.dealData();
        this.$emit('focus', evt);
      },
      /**
       * 透传blur方法
       */
      handleBlur(evt) {
        this.isFocus = false;
        this.$emit('blur', evt);
      },
      handleClear(evt) {
        this.isFocus = false;
        this.$emit('clear', evt);
      },
      /**
       * 联想下拉-显示下拉框时，回填选中项文字
       */
      visibleChange(evt) {
        // 联想下拉时，显示下拉框时，回填选中项文字
        if (this.remote && evt && this.$refs.select.currentPlaceholder !== this.placeholder) {
          this.$refs.select.selectedLabel = this.$refs.select.currentPlaceholder;
        }
      },

      dealData() {
        if (!this.remote) {
          this.remoteData = this.data;
          return;
        }
        if (this.value) {
          // const idArray = { [this.configSet.value]: this.value };
          // const newArray = []; // 新数组
          // let j = 0;
          // Object.keys(this.data).forEach(i => {
          //   if (idArray.id.length >= 2) {
          //     idArray.id.forEach(e => {
          //       if (this.data[i].id === e) {
          //         newArray[j++] = this.data[i];
          //       }
          //     });
          //   } else if (idArray.id.length < 2) {
          //     if (this.data[i].id === idArray.id) {
          //       newArray[j++] = this.data[i];
          //     }
          //   }
          // });
          // const obj = newArray || {};
          const [result] = this.data.filter(item => item[this.configSet.value] === this.value);
          if (result && result[this.configSet.label]) {
            this.remoteMethod(result[this.configSet.label]);
          }
          if (this.multiple) {
            this.handleChange(this.value);
          }
        } else {
          this.remoteData = this.take(this.data, this.searchMaxLength);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
