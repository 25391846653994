var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ykc-tree",style:({ width: _vm.format(_vm.width), paddingTop: !_vm.searchable && '0' }),attrs:{"tabindex":-1},on:{"blur":_vm.handleBlur}},[(_vm.selectable || _vm.searchable)?_c('div',{staticClass:"header"},[(
        _vm.selectType === 'checkbox' && _vm.data.length > 0 // 没有数据时不展示复选框
      )?_c('el-checkbox',{staticClass:"select",attrs:{"value":_vm.isAllChecked(),"indeterminate":_vm.isAllIndeterminate()},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleSelectAll.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.selectAllText)+" ")]):_vm._e(),(_vm.searchable)?_c('div',{staticClass:"search"},[_c('ykc-input',{staticClass:"ykc-tree-search-input",attrs:{"clearable":true,"placeholder":_vm.placeholder,"validate-event":false},on:{"clear":_vm.handleSearch},nativeOn:{"keyup":function($event){return _vm.handleKeyup.apply(null, arguments)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('ykc-button',{staticStyle:{"margin-left":"-1px"},on:{"click":_vm.handleSearch}},[_vm._v(_vm._s(_vm.searchText))])],1):_vm._e()],1):_vm._e(),_c('ykc-tree-list',{ref:"YkcTreeList",attrs:{"height":_vm.treeHeight,"width":_vm.width,"data":_vm.listData},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [(items.length > 0)?_vm._l((items),function(item){return _c('div',{key:item[_vm.nodeKey],class:[
            'ykc-tree-node',
            {
              'is-root': _vm.isRootNode(item),
            },
          ],style:({
            paddingLeft: `${_vm.selectable ? 12 : 32}px`,
          }),on:{"click":function($event){return _vm.handleNodeClick(item, item[_vm.nodeKey])},"contextmenu":function($event){return _vm.handleNodeContextmenu(item, item[_vm.nodeKey], $event)}}},[(_vm.selectable)?_c('div',{staticClass:"select"},[(_vm.selectType === 'checkbox')?_c('el-checkbox',{attrs:{"value":_vm.isNodeSelected(item),"indeterminate":_vm.isNodeIndeterminate(item),"disabled":_vm.isNodeDisabled(item)},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleCheckboxClick(item, item[_vm.nodeKey], $event, true)}}}):(_vm.selectType === 'radio')?_c('el-radio',{attrs:{"value":_vm.isNodeSelected(item),"label":true,"disabled":_vm.isNodeDisabled(item)},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleRadioClick(item, item[_vm.nodeKey], $event)}}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"content",style:({
              paddingLeft: _vm.calculatePaddingLeft(item),
            })},[(_vm.isNodeHasChildren(item))?_c('i',{class:['icon', `el-icon-caret-${_vm.isNodeExpanded(item) ? 'bottom' : 'right'}`],on:{"click":function($event){return _vm.handleExpandClick(item, item[_vm.Expanded], item[_vm.nodeKey], $event)}}}):_vm._e(),_vm._t("node",function(){return [_c('div',{class:['label', { 'is-leaf': !_vm.isNodeHasChildren(item) }]},[_vm._v(" "+_vm._s(_vm.renderLabel(item))+" ")])]},{"node":item})],2)])}):[_vm._t("empty",function(){return [_c('div',{staticClass:"ykc-tree-empty"},[_c('img',{attrs:{"src":_vm.emptyImageURL,"alt":"tree-empty"}}),_c('span',[_vm._v(_vm._s(_vm.emptyText))])])]})]]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }