<!--
 * @Author: Daokai Huo
 * @Date: 2021-10-13 15:58:04
 * @LastEditTime: 2023-03-01 10:20:42
 * @Description: 日期组件
-->
<template>
  <div class="ykc-date-wrap">
    <ykc-dropdown
      v-if="isDateType"
      :data="dropData"
      v-model="dropVal"
      @change="handleSelect"
      :clearable="false"></ykc-dropdown>
    <year-range v-if="type === 'yearrange'" :format="format" v-model="model"></year-range>
    <el-date-picker
      v-else
      class="ykc-date-picker"
      :editable="false"
      :disabled="disabled"
      ref="datePicker"
      :popperClass="`${this.onlyHour ? popperClass + ' hourHide' : ''} ${popperClass}`"
      v-model="model"
      :type="isDateType ? dropVal : type"
      :value-format="updateFormat || setFormat"
      :format="updateFormat || setFormat"
      :picker-options="pickerOptions"
      :range-separator="rangeSeparator"
      :placeholder="placeholder"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :default-time="defaultTime"
      :clearable="clearable"
      @change="handleChange"></el-date-picker>
  </div>
</template>
<script>
  import YearRange from '@/components/ykc-ui/date-picker/src/year-range';
  // import picker from '@/components/ykc-ui/date-picker/src/year-range/picker';

  export default {
    name: 'YkcDatePicker',
    components: {
      // picker,
      YearRange,
    },
    props: {
      // 获取的value值
      value: [String, Array],
      // 日期类型 year / month / date / datetime / datetimerange / daterange/ monthrange / yearrange
      type: {
        type: String,
        default: 'daterange',
      },
      // 日期格式 yyyy-MM-dd, yyyy-MM-dd HH:mm:ss等 需要根据传入的类型去设置
      format: {
        type: String,
        default: '',
      },
      // 限制日期: 默认不限制 ，limitBefore(限制当天之前日期不可选) limitAfter(限制当天之后日期不可选)
      // 传入日期（例如：2021-10-18）：会限制传入日期及之前日期不可选择，传入日期小于当前日期 当前日期之前不可选择
      limitDate: {
        type: String,
        default: '',
      },
      // 限制日期区间
      limitDateRange: {
        type: Array,
        default: () => {
          return [];
        },
      },
      // 是否限制当天 需要配合 limitDate/limitDateRange 使用
      limitToday: {
        type: Boolean,
        default: false,
      },
      // 选择时的占位内容
      placeholder: {
        type: String,
        default: '请选择日期',
      },
      // 选择时的占位内容
      startPlaceholder: {
        type: String,
        default: '请选择开始日期',
      },
      // 选择时的占位内容
      endPlaceholder: {
        type: String,
        default: '请选择结束日期',
      },
      // 选择范围时的分隔符
      rangeSeparator: {
        type: String,
        default: '-',
      },
      dropData: {
        type: Array,
        default: () => [
          { id: 'monthrange', name: '月' },
          { id: 'daterange', name: '日' },
        ],
      },
      // 是否使用 月日日期选择
      isDateType: {
        type: Boolean,
        default: false,
      },
      // 下拉默认值
      dropDefault: {
        type: String,
        default: '',
      },
      // popperClass
      popperClass: {
        type: String,
        default: 'ykc-date-picker',
      },
      // 显示到小时
      onlyHour: {
        type: Boolean,
        default: false,
      },
      // 是否限制一年可选
      limitOneYear: {
        type: Boolean,
        default: false,
      },
      defaultTime: [String, Array],
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      dropDefault: {
        // 首次绑定是否执行handle
        immediate: false,
        handler(val) {
          console.log('val', val);
          if (val) {
            this.dropVal = val;
          }
        },
      },
    },
    data() {
      return {
        updateFormat: '',
        dropVal: 'daterange',
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      /**
       * 根据type设置format
       */
      setFormat() {
        const formatType = {
          year: 'yyyy',
          month: 'yyyy-MM',
          date: 'yyyy-MM-dd',
          datetime: 'yyyy-MM-dd HH:mm:ss',
        };
        let format = '';
        Object.keys(formatType).forEach(item => {
          const type = this.isDateType ? this.dropVal : this.type;
          if (type.startsWith(item)) {
            format = formatType[item];
          }
        });
        return this.format || format;
      },
      /**
       * 限制日期计算
       */
      pickerOptions() {
        return {
          disabledDate: date => {
            let state = false;
            // true 不能选定 false 可以选定
            // 限制日期区间
            if (this.limitDateRange.length && !state) {
              state =
                date &&
                (date.valueOf() < new Date(this.limitDateRange[0]).valueOf() ||
                  date.valueOf() > new Date(this.limitDateRange[1]).valueOf());
            }
            if (this.limitDate && !state) {
              const nowDate = Date.now();
              const before = this.limitToday ? nowDate : nowDate - 86400000;
              const after = this.limitToday ? nowDate - 86400000 : nowDate;
              let oneYearBefore = nowDate - 1000 * 60 * 60 * 24 * 365;
              const dateNow = new Date();
              // 只能选择一年
              oneYearBefore = new Date(
                `${dateNow.getFullYear() - 1}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`
              ).valueOf();
              // 当天之前不能选择
              if (this.limitDate === 'limitBefore' || this.limitDate === 'limitBeforeTime') {
                state = date && date.valueOf() < before;
                // 禁止当前之后不能选择
              } else if (this.limitDate === 'limitAfter') {
                state = date && date.valueOf() > after;
              } else {
                // 根据传入时间限制， 传入时间之前及传入的时间不能选择
                /* eslint-disable */
                // 如果所传时间小于当前时间，当前时间之前的不能选，
                // if (new Date(this.limitDate).valueOf() < nowDate) {
                //   state = date && date.valueOf() < nowDate - 86400000;
                //   // 否则，传入时间及之前的不能选
                // } else {
                //   state = date && date.valueOf() <= new Date(this.limitDate).valueOf();
                // }
                state = date && date.valueOf() <= new Date(this.limitDate).valueOf();
              }
              if (this.limitOneYear) {
                if (state == false) {
                  state = date && date.valueOf() <= oneYearBefore;
                }
              }
            }
            return state;
          },
        };
      },
    },
    methods: {
      /**
       * 日期选择
       */
      handleChange(date) {
        this.$emit('change', { event: 'change', date, select: this.dropVal });
      },
      /**
       * 下拉选择
       */
      handleSelect(select) {
        this.$emit('change', { event: 'select', date: this.value, select });
      },
    },
  };
</script>
<style lang="scss">
  @import './index.scss';
  .ykc-date-wrap {
    display: flex;
    .ykc-dropdown {
      width: 60px;
      .el-input__inner {
        padding: 0 5px 0 10px;
      }
    }
  }
  .el-select-dropdown__list {
    min-width: inherit;
  }
  .hourHide {
    .el-time-spinner {
      display: flex;
      justify-content: center;

      .el-scrollbar__wrap {
        overflow: inherit;
        overflow-y: scroll;
      }

      .el-time-spinner__wrapper:nth-child(2) {
        display: none;
      }
    }
  }
</style>
